/* eslint-disable max-len */
import slider1 from '../images/slider1.jpeg';
import slider2 from '../images/slider2.jpeg';
import slider3 from '../images/slider3.jpeg';
import slider4 from '../images/slider4.jpg';
import slider5 from '../images/slider5.jpeg';
import slider6 from '../images/slider6.jpg';
import slider7 from '../images/slider7.jpg';
import slider8 from '../images/slider8.jpg';

export const sliderData = [
  {
    image: slider1,

  },
  {
    image: slider2,

  },
  {
    image: slider3,

  },
  {
    image: slider4,

  },
  {
    image: slider5,

  },
  {
    image: slider6,

  },
  {
    image: slider7,

  },
  {
    image: slider8,

  },
];

