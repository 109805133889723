/* eslint-disable max-len */
import image1 from '../images/windowWashing.webp';
import image2 from '../images/windowWashing2.webp';
export const sliderData = [
  {
    image: image1,

  },
  {
    image: image2,

  },
];

