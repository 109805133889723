/* eslint-disable max-len */
import main1 from '../../images/main1.jpg';
import main2 from '../../images/main2.jpg';
import main3 from '../../images/main3.jpg';
import main4 from '../../images/main4.jpg';
export const sliderData = [
  {
    image: main2,
    heading: 'Slide Two',
    desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
  },
  {
    image: main3,
    heading: 'Slide Three',
    desc: 'This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
  },
  {
    image: main4,
    heading: 'Slide Three',
    desc: 'This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
  },
  {
    image: main1,
    heading: 'Slide One',
    desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
  },
];
