/* eslint-disable max-len */
import image1 from '../images/houseSlider1.jpg';
import image2 from '../images/houseSlider2.jpg';
import image3 from '../images/houseSlider3.jpg';
import image4 from '../images/houseSlider4.jpg';
export const sliderData = [
  {
    image: image1,

  },
  {
    image: image2,

  },
  {
    image: image3,

  },
  {
    image: image4,

  },
];

