import React from 'react';
import styles from './HomeStyle';
const About = () => {
  return (
    <div style={styles.conatiner}>
      <div style={{marginTop: '100px', zIndex: '1'}}>
                About
      </div>
    </div>
  );
};


export default About;
