/* eslint-disable max-len */
import image1 from '../images/power1.jpg';
import image3 from '../images/power3.jpg';
import image4 from '../images/power4.jpg';
export const sliderData = [
  {
    image: image1,

  },
  {
    image: image3,

  },
  {
    image: image4,

  },
];

